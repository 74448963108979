import React from 'react';
import { AiFillPhone, AiFillHome, AiFillMail } from 'react-icons/ai';

function Footer() {
  return (
    <div className="bg-black text-white text-sm md:text-base pt-2">
      <div className="grid grid-cols-1 items-center md:grid-cols-3 gap-3 mx-4 md:mx-20 mt-2">
        <div className="flex items-center justify-center md:justify-normal">
          <AiFillHome color={'#F87315'} size={25} />
          <p className="ml-3">Jacksonville, Florida</p>
        </div>
        <div className="flex items-center justify-center">
          <AiFillMail color={'#F87315'} size={25} />
          <a className="ml-3" href="mailto:gabegavoci@gmail.com">
            gabegavoci@gmail.com
          </a>
        </div>
        <div className="flex items-center justify-center ">
          <AiFillPhone color={'#F87315'} size={25} />
          <a className="ml-3" href="tel:9043772687">
            (904)-377-2687
          </a>
        </div>
      </div>

      <p className="text-center text-[6px] pt-6 pb-4 md:pt-2 md:pb-1 text-orange-500">
        Copyright © 2024 Super Handy Home - All Rights Reserved.
      </p>
    </div>
  );
}

export default Footer;
