import React, { useState } from 'react';
import logo from '../assets/logo.png';
import {
  AiOutlineClose,
  AiOutlineMenu,
  AiFillPhone,
  AiFillHome,
  AiFillMail,
  AiFillInfoCircle,
  AiFillTool,
  AiOutlineFormatPainter,
} from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Navbar = () => {
  const [mobile, setMobile] = useState(false);

  const handleMobile = () => {
    setMobile(!mobile);
  };

  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 75) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  window.addEventListener('scroll', handleScroll);

  return (
    <div
      className={
        isScrolled
          ? 'fixed top-0 left-0 h-[75px] mx-auto px-4 md:px-20 text-black w-full bg-white shadow-[rgba(0,0,15,0.5)_0px_5px_4px_0px] z-20'
          : 'fixed top-0 left-0 h-[75px] mx-auto px-4 md:px-20 text-white w-full z-20'
      }
    >
      <div
        className={
          mobile
            ? 'flex justify-between items-center '
            : 'flex justify-between items-center '
        }
      >
        <HashLink to="/#Home" className="flex mt-2">
          <img
            src={logo}
            alt="logo"
            className={
              mobile ? 'w-14 h-14 md:w-14 md:h-14' : 'w-14 h-14 md:w-14 md:h-14'
            }
          />
        </HashLink>

        <div className="hidden md:flex">
          <HashLink
            to="/#Home"
            className="p-4 hover:text-orange-500 hover:font-black"
          >
            Home
          </HashLink>
          <HashLink
            to="/#About"
            className="p-4 hover:text-orange-500 hover:font-black"
          >
            About
          </HashLink>
          <HashLink
            to="/#Services"
            className="p-4 hover:text-orange-500 hover:font-black"
          >
            Services
          </HashLink>
          <HashLink
            to="/#Projects"
            className="p-4 hover:text-orange-500 hover:font-black"
          >
            Projects
          </HashLink>
          <HashLink
            to="/#Contact"
            className="p-4 hover:text-orange-500 hover:font-black"
          >
            Contact
          </HashLink>
          <a
            href="tel:9043772687"
            className="bg-[#ffa500] rounded-md my-2 ml-4 px-3 text-black flex items-center justify-center shadow-sm shadow-current hover:bg-orange-500"
          >
            <AiFillPhone className="mr-2" />
            <p>904-377-2687</p>
          </a>
        </div>
        <div onClick={handleMobile} className="block md:hidden ">
          {mobile ? (
            <AiOutlineClose size={20} color={isScrolled ? 'black' : 'white'} />
          ) : (
            <AiOutlineMenu size={20} color={isScrolled ? 'black' : 'white'} />
          )}
        </div>
      </div>
      <div
        className={
          mobile
            ? 'fixed flex flex-col left-0 top-0 w-[45%] h-full border-r border-gray-900 bg-white text-black ease-in-out duration-500'
            : 'flex flex-col ease-in-out duration-500 fixed left-[-100%]'
        }
      >
        <HashLink to="/#Home">
          <img src={logo} alt="logo" className="w-14 h-14 m-auto mt-4" />
        </HashLink>
        <HashLink
          to="/#Home"
          className="mt-4 p-4 border-b border-gray-600 flex items-center"
        >
          <AiFillHome className="mr-2" color={'#ffa500'} />
          Home
        </HashLink>
        <HashLink
          to="/#About"
          className="p-4 border-b border-gray-600 flex items-center"
        >
          <AiFillInfoCircle className="mr-2" color={'#ffa500'} />
          About
        </HashLink>
        <HashLink
          to="/#Services"
          className="p-4 border-b border-gray-600 flex items-center"
        >
          <AiFillTool className="mr-2" color={'#ffa500'} />
          Services
        </HashLink>
        <HashLink
          to="/#Projects"
          className="p-4 border-b border-gray-600 flex items-center"
        >
          <AiOutlineFormatPainter className="mr-2" color={'#ffa500'} />
          Projects
        </HashLink>
        <HashLink
          to="/#Contact"
          className="p-4 border-b border-gray-600 flex items-center"
        >
          <AiFillMail className="mr-2" color={'#ffa500'} />
          Contact
        </HashLink>
        <a href="tel:9043772687" className="p-4 text-black flex items-center ">
          <AiFillPhone className="mr-2" color={'#ffa500'} />
          <p>904-377-2687</p>
        </a>
      </div>
    </div>
  );
};

export default Navbar;
