import React from 'react';
import ribbon from '../assets/ribbon.png';
import calendar from '../assets/calendar.png';
import home from '../assets/home.png';
import clean from '../assets/broom.png';
import price from '../assets/price.png';
import convo from '../assets/chat.png';
import { HashLink } from 'react-router-hash-link';

const MiniAbout = () => {
  return (
    <div className="bg-[url('assets/bgroof.jpeg')] text-white pb-10">
      <div className="flex justify-center items-center pt-10 mx-12 md:mx-auto">
        <hr className="w-[7%] mx-8 border-1 border-white hidden md:block" />
        <p className="text-4xl md:text-5xl text-center font-bold">
          Why Choose Super Handy <span className="text-orange-500">Home?</span>
        </p>
        <hr className="w-[7%] mx-8 border-1 border-white hidden md:block" />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-6 justify-start items-start gap-6 mx-20 mt-20">
        <div className="flex flex-col justify-center items-center">
          <img className="w-[75px] h-[75px]" src={ribbon} alt="quality" />
          <p className="text-center my-10">
            To stay on top of your project from start to finish, ensuring
            complete satisfaction.
          </p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img className="w-[75px] h-[75px]" src={calendar} alt="time" />
          <p className="text-center my-10">
            To set and meet all deadlines for the duration of the entire
            project.
          </p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img className="w-[75px] h-[75px]" src={home} alt="protect" />
          <p className="text-center my-10">
            To protect and prepare your home before and during all work.
          </p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img className="w-[75px] h-[75px]" src={clean} alt="clean" />
          <p className="text-center my-10">
            To clean up daily and leave your property as clean as we found it.
          </p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img className="w-[75px] h-[75px]" src={price} alt="price" />
          <p className="text-center my-10">
            To accurately price your job so your project will be free from
            hidden costs.
          </p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img className="w-[75px] h-[75px]" src={convo} alt="communicate" />
          <p className="text-center my-10">
            To avoid surprises by means of consistent communication. We spell it
            out for you!
          </p>
        </div>
      </div>
      <div className="flex justify-center">
        <HashLink to="/#Contact">
          <button className="mx-auto rounded-sm shadow-sm shadow-black bg-[#FFA500] hover:bg-orange-500 py-2 px-4">
            Learn More About Our Company
          </button>
        </HashLink>
      </div>
    </div>
  );
};

export default MiniAbout;
