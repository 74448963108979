import React from 'react';
import { ReactTyped } from 'react-typed';
import { HashLink } from 'react-router-hash-link';

const Hero = () => {
  return (
    <div className="w-full h-screen relative overflow-hidden">
      <div>
        <div className="bg-landingimage bg-cover h-screen w-screen -z-10"></div>
        <div className="absolute top-0 left-0 w-full h-screen bg-black bg-opacity-30 text-white text-shadow-sm shadow-gray-600 flex content-center justify-center">
          <div className="text-center flex flex-col justify-center">
            <p className="text-[#ffa500] font-bold p-2" id="Home">
              Jacksonville's Trusted
            </p>
            <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6">
              Home Contractors
            </h1>
            <p className="md:text-5xl sm:text-4xl text-xl font-bold py-4">
              Here For All Your
            </p>
            <div className="flex justify-center items-center text-[#ffa500]">
              <ReactTyped
                className="md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2"
                strings={['Kitchen', 'Bathroom', 'Flooring', 'Remodeling']}
                typeSpeed={120}
                backSpeed={140}
                loop
              />
              <p className="md:text-5xl sm:text-4xl text-xl font-bold py-4 text-white">
                Needs
              </p>
            </div>
            <HashLink to="/#Contact">
              <button className="bg-[#ffa500] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black shadow-sm shadow-black hover:bg-orange-500">
                Get Started
              </button>
            </HashLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
