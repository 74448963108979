import fivestar from '../assets/fivestar.png';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';

function Section5() {
  return (
    <div className="pb-10 md:pb-20 text-black bg-gray-100 relative">
      <div className="hidden lg:block absolute right-0 top-0">
        <div className="bg-orange-500 h-[40rem] w-[30rem] z-0 rounded-l-full"></div>
      </div>
      <div>
        <p className="font-bold text-4xl md:text-5xl text-center pt-10 mb-3">
          Our <span className="text-orange-500">Reviews</span>
        </p>
        <hr className="border-1 border-[#FFA500] w-20 mx-auto rounded-md" />
      </div>
      <div className="flex flex-col lg:flex-row w-full h-[950px] lg:h-[350px] mt-8 lg:mt-14">
        <div className="bg-white rounded-xl w-[350px] lg:w-[350px] mx-auto lg:ml-32 2xl:ml-80 p-5 shadow-[#FFA500] shadow-md overflow-auto">
          <p className="font-medium text-xl mb-5">Henry C.</p>
          <img src={fivestar} alt="five stars" className="w-28 h-5 mb-5" />
          <p className="text-gray-500 text-sm">
            "Our kitchen remodel with this company was fantastic. They listened
            to our needs and delivered a beautiful, functional space. The team
            was professional, detail-oriented, and kept us informed throughout
            the project. Even with a few challenges, they stayed on track and
            exceeded our expectations."
          </p>
        </div>
        <div className="bg-white rounded-xl w-[350px] lg:w-[350px] mx-auto lg:mx-8 p-5 mt-8 lg:mt-0 shadow-[#FFA500] shadow-md overflow-auto z-10">
          <p className="font-medium text-xl mb-5">Elliot S.</p>
          <img src={fivestar} alt="five stars" className="w-28 h-5 mb-5" />
          <p className="text-gray-500 text-sm">
            "We hired them to replace the flooring in our home, and the results
            are amazing. The team helped us choose the right materials and
            completed the installation smoothly. They handled tricky areas with
            skill, and our new hardwood floors have completely transformed our
            space."
          </p>
        </div>
        <div className="bg-white rounded-xl w-[350px] lg:w-[350px] mx-auto lg:mr-32 2xl:mr-80 z-10 p-5 mt-8 lg:mt-0 shadow-[#FFA500] shadow-md overflow-auto">
          <p className="font-medium text-xl mb-5">Brandon C.</p>
          <img src={fivestar} alt="five stars" className="w-28 h-5 mb-5" />
          <p className="text-gray-500 text-sm">
            "I had a variety of small repairs, and their handyman service was
            excellent—on time, professional, and knowledgeable. Each task was
            done efficiently, and they explained the repairs along the way. It's
            hard to find reliable help, but this team made everything easy and
            stress-free."
          </p>
        </div>
      </div>
      <div className="flex justify-center mt-10">
        <HashLink
          to="/#Contact"
          className="bg-[#FFA500] rounded-sm text-white px-6 py-3 shadow-black shadow-sm hover:bg-orange-500"
        >
          Get In Touch
        </HashLink>
      </div>
    </div>
  );
}

export default Section5;
