// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import slide1 from '../assets/slide1.jpg';
import slide2 from '../assets/slide2.png';
import slide3 from '../assets/slide3.jpg';
import slide4 from '../assets/slide4.jpg';

import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

function Projects() {
  const projectImages = [
    {
      id: 1,
      src: slide1,
      alt: 'Project 1',
    },
    {
      id: 2,
      src: slide2,
      alt: 'Project 2',
    },
    {
      id: 3,
      src: slide3,
      alt: 'Project 3',
    },
    {
      id: 4,
      src: slide4,
      alt: 'Project 4',
    },
  ];

  return (
    <div className="w-full flex flex-col items-center justify-center py-12 bg-white">
      {/* Header */}
      <div className="mb-8 md:mb-6">
        <p
          id="Projects"
          className="font-bold text-4xl md:text-5xl text-center mb-2 md:mb-4"
        >
          Our <span className="text-orange-500">Projects</span>
        </p>
        <hr className="border-1 border-[#FFA500] w-20 mx-auto rounded-md" />
      </div>

      {/* Image Slider */}
      <div className="w-[80%] md:w-[80%] max-w-4xl mb-6 md:mb-6 md:mt-4">
        <Swiper
          cssMode={true}
          navigation={true}
          pagination={true}
          mousewheel={true}
          keyboard={true}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          className="rounded-lg overflow-hidden shadow-lg h-[270px] md:h-[500px]"
        >
          {projectImages.map(image => (
            <SwiperSlide key={image.id} className="">
              <img
                src={image.src}
                alt={image.alt}
                className="w-full h-[270px] md:h-[500px] object-contain"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default Projects;
