import React from 'react';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import MiniServices from '../components/MiniServices';
import Reviews from '../components/Reviews';
import MiniContact from '../components/MiniContact';
import Footer from '../components/Footer';
import MiniAbout from '../components/MiniAbout';
import About from '../components/About';
import Projects from '../components/Projects';

const Home = () => {
  return (
    <>
      <Hero />
      <About />
      <MiniServices />
      <MiniAbout />
      <Projects />
      <Reviews />
      <MiniContact />
      <Footer />
      <Navbar />
    </>
  );
};

export default Home;
