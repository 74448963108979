import React from 'react';

import kitchen from '../assets/kitchen-shh.webp';
import bathroom from '../assets/bathroom-shh.jpg';
import painting from '../assets/painting-shh.jpeg';
import flooring from '../assets/flooring-shh.jpg';
import handyman from '../assets/handyman-shh.png';
import extra from '../assets/additional-shh.webp';
import { HashLink } from 'react-router-hash-link';

const MiniServices = () => {
  return (
    <div>
      <div className="flex justify-center items-center pt-10">
        <hr className="w-[15%] mx-8 border-1 border-black" />
        <p className="text-4xl md:text-5xl text-center font-bold" id="Services">
          Our <span className="text-orange-500">Services</span>
        </p>
        <hr className="w-[15%] mx-8 border-1 border-black" />
      </div>
      <p className="text-center pt-6 pb-10 mx-8 md:mx-auto">
        Super Handy Home is an award-winning installation and service
        contractor.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 md:gap-20 justify-center items-start md:px-14 pb-20 gap-12">
        <div className="mx-20 md:mx-auto">
          <img
            className="w-full md:h-80 rounded-sm object-cover"
            src={kitchen}
            alt="kitchen renovation"
          />
          <p className="font-medium text-2xl my-4">Kitchen Renovation</p>
          <p>
            Revitalize your kitchen with our expert renovation services. From
            modernizing cabinets and countertops to redesigning layouts, Super
            Handy Home creates stylish, functional kitchens that suit your taste
            and lifestyle. Let us help you transform your kitchen into a space
            that adds value to your home and brings joy to everyday living.
          </p>
          <HashLink to="/#Contact">
            <button className="bg-[#ffa500] text-white rounded-sm shadow-sm shadow-black px-3 py-1 hover:bg-orange-500 mt-6 self-end">
              Learn More
            </button>
          </HashLink>
        </div>
        <div className="mx-20 md:mx-auto">
          <img
            className="w-full md:h-80 rounded-sm object-cover"
            src={bathroom}
            alt="bathroom renovation"
          />
          <p className="font-medium text-2xl my-4">Bathroom Renovation</p>
          <p>
            Refresh your bathroom with our expert renovation services. Super
            Handy Home transforms bathrooms into modern, relaxing spaces. From
            updating fixtures and tiles to optimizing layouts, we ensure your
            bathroom is both stylish and functional. Let us create a space that
            enhances your home’s value and comfort.
          </p>
          <HashLink to="/#Contact">
            <button className="bg-[#ffa500] text-white rounded-sm shadow-sm shadow-black px-3 py-1 hover:bg-orange-500 mt-6 self-end">
              Learn More
            </button>
          </HashLink>
        </div>
        <div className="mx-20 md:mx-auto">
          <img
            className="w-full md:h-80 rounded-sm object-cover"
            src={flooring}
            alt="flooring install"
          />
          <p className="font-medium text-2xl my-4">Flooring Install</p>
          <p>
            Enhance your home with professional flooring installation. At Super
            Handy Home, we offer a range of options, from hardwood and laminate
            to tile and carpet, tailored to your style and needs. Our skilled
            team ensures a flawless installation that boosts your home's look
            and value. Trust us for beautiful floors that last.
          </p>
          <HashLink to="/#Contact">
            <button className="bg-[#ffa500] text-white rounded-sm shadow-sm shadow-black px-3 py-1 hover:bg-orange-500 mt-6 self-end">
              Learn More
            </button>
          </HashLink>
        </div>
        <div className="mx-20 md:mx-auto">
          <img
            className="w-full md:h-80 rounded-sm object-cover"
            src={painting}
            alt="painting service"
          />
          <p className="font-medium text-2xl my-4">
            Interior and Exterior Painting
          </p>
          <p>
            Give your home a fresh look with our interior and exterior painting
            services. Super Handy Home offers quality painting that improves
            both curb appeal and indoor atmosphere. Whether it’s a single room
            or the whole exterior, we ensure a clean, durable finish. Count on
            us to brighten your spaces with expert painting.
          </p>
          <HashLink to="/#Contact">
            <button className="bg-[#ffa500] text-white rounded-sm shadow-sm shadow-black px-3 py-1 hover:bg-orange-500 mt-6 self-end">
              Learn More
            </button>
          </HashLink>
        </div>
        <div className="mx-20 md:mx-auto">
          <img
            className="w-full md:h-80 rounded-sm object-cover"
            src={handyman}
            alt="handyman service"
          />
          <p className="font-medium text-2xl my-4">Handyman Service</p>
          <p>
            Handle all your home repairs with our versatile handyman services.
            At Super Handy Home, we tackle everything from minor fixes to larger
            projects with top-notch quality and care. Whether it’s fixing a
            leaky faucet, assembling furniture, or handling small renovations,
            trust us for reliable, professional assistance with any home task.
          </p>
          <HashLink to="/#Contact">
            <button className="bg-[#ffa500] text-white rounded-sm shadow-sm shadow-black px-3 py-1 hover:bg-orange-500 mt-6 self-end">
              Learn More
            </button>
          </HashLink>
        </div>
        <div className="mx-20 md:mx-auto">
          <img
            className="w-full md:h-80 rounded-sm object-cover"
            src={extra}
            alt="additional services"
          />
          <p className="font-medium text-2xl my-4">Additional Services</p>
          <p>
            Explore our extra services for all your home needs. At Super Handy
            Home, we offer solutions beyond remodeling, including custom
            carpentry, drywall repairs, and more. Our skilled team delivers
            quality results and precise workmanship for any project. Trust us
            for the additional touches that make your home truly unique.
          </p>
          <HashLink to="/#Contact">
            <button className="bg-[#ffa500] text-white rounded-sm shadow-sm shadow-black px-3 py-1 hover:bg-orange-500 mt-6 self-end">
              Learn More
            </button>
          </HashLink>
        </div>
      </div>
    </div>
  );
};

export default MiniServices;
