import React from 'react';
import emailjs from '@emailjs/browser';
import { useEffect } from 'react';

function MiniContact() {
  let templateParams = {
    name: 'Null',
    email: 'Null',
    phone: 'Null',
    message: 'Null',
    address: 'Null',
  };

  useEffect(() => {
    let form = document.getElementById('form');

    form.addEventListener('submit', e => {
      e.preventDefault();
      templateParams.name = document.getElementById('name').value;
      templateParams.phone = document.getElementById('phone').value;
      templateParams.message = document.getElementById('message').value;
      templateParams.email = document.getElementById('email').value;
      templateParams.address = document.getElementById('address').value;

      emailjs
        .send(
          'contact_service',
          'contact_form2',
          templateParams,
          'W1TvKP0HGHof0CLMz'
        )
        .then(
          response => {
            console.log('SUCCESS!', response.status, response.text);
          },
          err => {
            console.log('FAILED...', err);
          }
        );

      window.scrollTo(0, 0);
    });
  }, []);

  return (
    <div className='bg-cover bg-center pt-20 pb-1 bg-[url("assets/background-kitchen.webp")]'>
      <div className="flex flex-col md:flex-row bg-gray-200 w-[80%] z-30 rounded-3xl mx-auto mb-10 md:mb-20 items-center md:items-stretch">
        <iframe
          src="https://www.google.com/maps/embed/v1/place?q=Jacksonville&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
          className="b-0 w-full h-72 md:h-auto"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          title="jaxmap"
        ></iframe>
        <div className="">
          <p
            id="Contact"
            className="font-bold text-4xl pt-6 mb-3 ml-4 md:mx-auto md:flex md:justify-center lg:block lg:ml-14"
          >
            Contact <span className="text-orange-500">Us</span>
          </p>
          <hr className="border-2 border-[#ffa500] rounded-md w-[70px] ml-4 md:mx-auto lg:ml-14" />
          <div className="ml-4 md:mx-auto lg:mx-0 md:flex md:flex-col md:items-center lg:block lg:ml-14 lg:w-[380px]">
            <form id="form" className="flex flex-col">
              <input
                type="text"
                id="name"
                name="name"
                className="w-[260px] md:w-[320px] h-[50px] mt-8 rounded-lg indent-3"
                placeholder="Name"
              />
              <input
                type="email"
                id="email"
                name="email"
                className="w-[260px] md:w-[320px] h-[50px] mt-4 rounded-lg indent-3"
                placeholder="E-mail"
              />
              <input
                type="text"
                id="phone"
                name="phone"
                className="w-[260px] md:w-[320px] h-[50px] mt-4 rounded-lg indent-3"
                placeholder="Phone Number"
              />
              <input
                type="text"
                id="address"
                name="address"
                className="w-[260px] md:w-[320px] h-[50px] mt-4 rounded-lg indent-3"
                placeholder="Address"
              />
              <textarea
                name="message"
                id="message"
                className="w-[260px] md:w-[320px] h-[50px] mt-4 rounded-lg indent-3"
                placeholder="Message"
                cols="30"
                rows="10"
              ></textarea>

              <input
                type="submit"
                className="cursor-pointer w-[160px] h-[50px] my-8 rounded-md bg-[#ffa500] text-white font-semibold hover:bg-orange-500 lg:ml-20 mx-auto lg:mx-0"
                value="Submit"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MiniContact;
