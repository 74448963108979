import { Link } from 'react-router-dom';
import s3image1 from '../assets/s3image1.webp';

function About() {
  return (
    <div
      className="lg:block h-[625px] md:h-[575px] lg:h-[65vh] text-black bg-gray-100 relative overflow-auto"
      id="About"
    >
      <div className="absolute right-0 top-0">
        <div className="hidden lg:block bg-orange-500 h-80 w-96 z-0"></div>
      </div>
      <div>
        <p className="font-bold text-5xl text-center pt-8 md:pt-10 mb-3">
          About <span className="text-orange-500">Us</span>
        </p>
        <hr className="border-1 border-orange-300 mx-auto w-16 rounded-md" />
      </div>
      <div className="flex mt-8 md:mt-10 justify-center">
        <div className="w-3/4 lg:w-2/5 h-full lg:mr-20 text-sm md:text-base text-center">
          <p>
            Welcome to Super Handy Home! We are a family-owned remodeling
            business based in Jacksonville, FL, ready to help with your home
            projects. Our journey began with house flipping, and over the years,
            we've flipped hundreds of homes. This experience has honed our
            renovation skills and taught us how to enhance a property's value
            with smart, impactful improvements.
            <br></br>
            <br></br>
            Having worked with hundreds of families, we understand the
            importance of hiring the right contractor to avoid unnecessary costs
            and headaches. That's why we're committed to getting it right the
            first time. Our goal is to transform your home and maximize its
            value.
            <br></br>
            <br></br>
            At Super Handy Home, we pride ourselves on delivering exceptional
            work and building strong client relationships. Let us bring your
            vision to life and elevate your home's value!
          </p>
        </div>
        <img
          src={s3image1}
          alt="HVAC Maintenance"
          className="h-72 w-1/3 rounded-3xl z-10 hidden lg:inline"
        />
      </div>
    </div>
  );
}

export default About;
